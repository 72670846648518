<template>
  <div id="page">
    <Header />
    <Footer />
    <PageTitle :title="'Shop'" />
    <div class="page-title-clear"></div>
    <div class="page-content">
      <div class="content mt-n4 mb-4">
        <div
          class="
            search-box search-dark
            shadow-sm
            border-0
            mt-4
            bg-theme
            rounded-sm
            bottom-0
          "
        >
          <i class="fa fa-search ms-1"></i>
          <input
            type="text"
            class="border-0"
            placeholder="Searching for something? Try 'neon'"
            data-search
          />
        </div>
      </div>

      <div class="content mb-0">
        <div class="d-flex">
          <div class="align-self-center"></div>
        </div>
        <a href="#" data-menu="order-2">
          <div class="row mb-0">
            <div class="col-6">
              <div
                class="card card-style mx-0 mb-4"
                style="background-image: url(/img/shop/shop_1.jpg)"
                data-card-height="170"
              >
                <div class="card-bottom p-3">
                  <h2 class="color-white">Shop 1</h2>
                  <p class="color-white opacity-60 line-height-s font-12"></p>
                </div>
                <div class="card-overlay bg-gradient opacity-30"></div>
                <div class="card-overlay bg-gradient"></div>
              </div>

              <div
                class="card card-style mx-0 mb-4"
                style="background-image: url(/img/shop/shop_2.jpg)"
                data-card-height="170"
              >
                <div class="card-bottom p-3">
                  <h2 class="color-white">Shop 2</h2>
                  <p class="color-white opacity-60 line-height-s font-12"></p>
                </div>
                <div class="card-overlay bg-gradient opacity-30"></div>
                <div class="card-overlay bg-gradient"></div>
              </div>
            </div>

            <div class="col-6">
              <div
                class="card card-style mx-0 mb-4"
                style="background-image: url(/img/shop/shop_3.jpg)"
                data-card-height="170"
              >
                <div class="card-bottom p-3">
                  <h2 class="color-white">Shop 1</h2>
                  <p class="color-white opacity-60 line-height-s font-12"></p>
                </div>
                <div class="card-overlay bg-gradient opacity-30"></div>
                <div class="card-overlay bg-gradient"></div>
              </div>

              <div
                class="card card-style mx-0 mb-4"
                style="background-image: url(/img/shop/shop_4.jpg)"
                data-card-height="170"
              >
                <div class="card-bottom p-3">
                  <h2 class="color-white">Shop 2</h2>
                  <p class="color-white opacity-60 line-height-s font-12"></p>
                </div>
                <div class="card-overlay bg-gradient opacity-30"></div>
                <div class="card-overlay bg-gradient"></div>
              </div>
            </div>

            <div class="col-6">
              <div
                class="card card-style mx-0 mb-4"
                style="background-image: url(/img/shop/shop_5.jpg)"
                data-card-height="170"
              >
                <div class="card-bottom p-3">
                  <h2 class="color-white">Shop 3</h2>
                  <p class="color-white opacity-60 line-height-s font-12"></p>
                </div>
                <div class="card-overlay bg-gradient opacity-30"></div>
                <div class="card-overlay bg-gradient"></div>
              </div>

              <div
                class="card card-style mx-0 mb-4"
                style="background-image: url(/img/shop/shop_6.jpg)"
                data-card-height="170"
              >
                <div class="card-bottom p-3">
                  <h2 class="color-white">Shop 4</h2>
                  <p class="color-white opacity-60 line-height-s font-12"></p>
                </div>
                <div class="card-overlay bg-gradient opacity-30"></div>
                <div class="card-overlay bg-gradient"></div>
              </div>
            </div>

            <div class="col-6">
              <div
                class="card card-style mx-0 mb-4"
                style="background-image: url(/img/shop/shop_7.jpg)"
                data-card-height="170"
              >
                <div class="card-bottom p-3">
                  <h2 class="color-white">Shop 5</h2>
                  <p class="color-white opacity-60 line-height-s font-12"></p>
                </div>
                <div class="card-overlay bg-gradient opacity-30"></div>
                <div class="card-overlay bg-gradient"></div>
              </div>

              <div
                class="card card-style mx-0 mb-4"
                style="background-image: url(/img/shop/shop_8.jpg)"
                data-card-height="170"
              >
                <div class="card-bottom p-3">
                  <h2 class="color-white">Shop 1</h2>
                  <p class="color-white opacity-60 line-height-s font-12"></p>
                </div>
                <div class="card-overlay bg-gradient opacity-30"></div>
                <div class="card-overlay bg-gradient"></div>
              </div>
            </div>

            <div class="col-6">
              <div
                class="card card-style mx-0 mb-4"
                style="background-image: url(/img/shop/shop_1.jpg)"
                data-card-height="170"
              >
                <div class="card-bottom p-3">
                  <h2 class="color-white">Shop 2</h2>
                  <p class="color-white opacity-60 line-height-s font-12"></p>
                </div>
                <div class="card-overlay bg-gradient opacity-30"></div>
                <div class="card-overlay bg-gradient"></div>
              </div>

              <div
                class="card card-style mx-0 mb-4"
                style="background-image: url(/img/shop/shop_2.jpg)"
                data-card-height="170"
              >
                <div class="card-bottom p-3">
                  <h2 class="color-white">Shop 3</h2>
                  <p class="color-white opacity-60 line-height-s font-12"></p>
                </div>
                <div class="card-overlay bg-gradient opacity-30"></div>
                <div class="card-overlay bg-gradient"></div>
              </div>
            </div>

            <div class="col-6">
              <div
                class="card card-style mx-0 mb-4"
                style="background-image: url(/img/shop/shop_3.jpg)"
                data-card-height="170"
              >
                <div class="card-bottom p-3">
                  <h2 class="color-white">Shop 2</h2>
                  <p class="color-white opacity-60 line-height-s font-12"></p>
                </div>
                <div class="card-overlay bg-gradient opacity-30"></div>
                <div class="card-overlay bg-gradient"></div>
              </div>

              <div
                class="card card-style mx-0 mb-4"
                style="background-image: url(/img/shop/shop_7.jpg)"
                data-card-height="170"
              >
                <div class="card-bottom p-3">
                  <h2 class="color-white">Shop 3</h2>
                  <p class="color-white opacity-60 line-height-s font-12"></p>
                </div>
                <div class="card-overlay bg-gradient opacity-30"></div>
                <div class="card-overlay bg-gradient"></div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>

    <!-- Page content ends here-->
    <div @click="menuClose" class="menu-hider"></div>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import Header from "@/components/Header";
import PageTitle from "@/components/PageTitle";
import { init_template, menuClose } from "@/appkit.js";
export default {
  components: { Header, Footer, PageTitle },
  data() {
    return {
      event: {},
    };
  },
  mounted() {
    init_template();
  },
  methods: {
    menuClose,
  },
};
</script>
